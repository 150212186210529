import React from "react";
import Image from "../PublicComponents/Image";

import { dataServices } from "../../json/DataServicesHome";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { memo } from "react";

const ServicesCard = () => {
  const [t, i18n] = useTranslation();

  return (
    <>
      {dataServices.map((item) => {
        return (
          <Link key={item.id} to={item.path}>
            <div
              key={item.id}
              style={{ border: " 0.956px solid rgba(226, 226, 226, 0.20)" }}
              className={`border w-64 flex items-center justify-start gap-4 py-8 
              ${i18n.language === "ar" ? "pr-7 pl-3" : "pr-3 pl-7"} ${
                item.id === 1 && "!border-[#907a01] colorBlockchain"
              }
            ${
              item.id === 2 && "!border-[#0076f0] !border-b-[#1aa6df]  colorBtn"
            }
            } 
             `}
            >
              <Image src={item.image} alt={item.alt} />
              <h3 className=" text-base font-extrabold leading-6 ">
                {item.title[i18n.language]}
              </h3>
            </div>
          </Link>
        );
      })}
    </>
  );
};

export default memo(ServicesCard);
