import React, { memo } from "react";
import Title from "../PublicComponents/Title";
import Image from "../PublicComponents/Image";
import CardDetails from "./CardDetails";
import Pulse from "react-reveal/Pulse";

import iconBusinessDevelopment from "../../assets/images/HomeImages/iconBusiness Development.png";
import imageBusinessDevelopment from "../../assets/images/HomeImages/imageBusiness Development.webp";
import { useTranslation } from "react-i18next";

const BusinessDevelopment = () => {
  const [t, i18n] = useTranslation();

  return (
    <div
      className={`${
        i18n.language === "ar" ? "xl:mr-[50px]" : ""
      } xl:mx-[92px] lg:mx-10 mx-5`}
    >
      <div>
        <Title
          question={t("Business Development")}
          title={t("Developing Technology For The Future")}
        />
      </div>

      <div className="flex flex-wrap flex-col lg:flex-row items-start gap-10 mt-16 mb-36">
        <Pulse>
          <span className="lg:w-[55%]  w-full">
            <Image
              src={imageBusinessDevelopment}
              alt="imageWeb3"
              className="w-full xl:h-[430px] lg:h-[490px]"
            />
          </span>
        </Pulse>

        <CardDetails
          className="lg:w-2/5 w-full flex justify-center items-center lg:text-start text-center  lg:items-start flex-col text-[#F7F7F7] gap-8"
          src={iconBusinessDevelopment}
          alt="imageBusinessDevelopment"
          head={t("manage everything")}
          title={t("Business Development")}
          description={t(
            "Digital transformation ignites business development by improving operations, expanding market reach, and fostering innovation. Embracing technology enhances customer experiences and unlocks growth opportunities in a dynamic and evolving market."
          )}
        />
      </div>
    </div>
  );
};

export default memo(BusinessDevelopment);
