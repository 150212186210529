import React from "react";
import Title from "../PublicComponents/Title";
import Image from "../PublicComponents/Image";
import CardDetails from "./CardDetails";
import Pulse from "react-reveal/Pulse";

import iconWeb3 from "../../assets/images/HomeImages/iconWeb3.png";
import imageWeb3 from "../../assets/images/HomeImages/imageWeb3.webp";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const Web3 = () => {
  const [t, i18n] = useTranslation();

  return (
    <div
      className={`${
        i18n.language === "ar" ? "xl:mr-[50px]" : ""
      } xl:mx-[92px] mx-10`}
    >
      <div>
        <Title question={t("Web3")} title={t("Make The World More Secure")} />
      </div>

      <div className="flex flex-wrap flex-col lg:flex-row items-start gap-10 mt-16 mb-36">
        <Pulse>
          <span className="lg:w-[55%] w-full">
            <Image
              src={imageWeb3}
              alt="imageWeb3"
              className="w-full xl:h-[460px] lg:h-[490px]"
            />
          </span>
        </Pulse>

        <CardDetails
          className="lg:w-2/5 w-full flex justify-center items-center lg:text-start text-center  lg:items-start flex-col text-[#F7F7F7] gap-8"
          src={iconWeb3}
          alt="iconWeb3"
          head={t("manage everything")}
          title={t("Why Web3 ??")}
          description={t(
            "Unleash the Power of Web 3: Transforming Businesses with Data Privacy, Automation, and Innovation. Tokenization opens new revenue streams, while open platforms drive global growth. Experience personalized journeys, enhanced credibility, and resilience in a user-centric digital era."
          )}
        />
      </div>
    </div>
  );
};

export default memo(Web3);
