import "./App.css";
import HomePage from "./pages/HomePage";
import "./i18n";
import { memo, lazy } from "react";

import { Route, Routes } from "react-router-dom";
import { Suspense } from "react";
const Navbar = lazy(() => import("./components/PublicComponents/Navbar"));
const OurServicesPage = lazy(() => import("./pages/OurServicesPage"));
const MobileDevelopmentPage = lazy(() =>
  import("./pages/OurServicesComponents/MobileDevelopmentPage")
);
const CustomerServicesPage = lazy(() =>
  import("./pages/OurServicesComponents/CustomerServicesPage")
);
const OurVisionPage = lazy(() => import("./pages/OurVisionPage"));
const BlockchainPage = lazy(() =>
  import("./pages/BlockchainComponents/BlockchainPage")
);
const ConsultingPage = lazy(() =>
  import("./pages/BlockchainComponents/ConsultingPage")
);
const TrainingPage = lazy(() => import("./pages/TrainingPage"));
const DapPage = lazy(() => import("./pages/BlockchainComponents/DapPage"));
const SmartContractSolutionPage = lazy(() =>
  import("./pages/BlockchainComponents/SmartContractSolutionPage")
);
const IntegrationWithBlockchainPage = lazy(() =>
  import("./pages/BlockchainComponents/IntegrationWithBlockchainPage")
);
const DevelopmentofBlockchainnetworkPage = lazy(() =>
  import("./pages/BlockchainComponents/DevelopmentofBlockchainnetworkPage")
);
const DigtiazationPage = lazy(() => import("./pages/DigtiazationPage"));
const OurBlogPage = lazy(() => import("./pages/OurBlogPage"));
const Footer = lazy(() => import("./components/PublicComponents/Footer"));
const WebProgrammingPage = lazy(() =>
  import("./pages/OurServicesComponents/WebProgrammingPage")
);
const DesktopSystemPage = lazy(() =>
  import("./pages/OurServicesComponents/WebProgramming/DesktopSystemPage")
);
const FixDebuggingPage = lazy(() =>
  import("./pages/OurServicesComponents/WebProgramming/FixDebuggingPage")
);
const ControlPanelPage = lazy(() =>
  import("./pages/OurServicesComponents/WebProgramming/ControlPanelPage")
);
const BasicWebsiteDevelopmentPage = lazy(() =>
  import(
    "./pages/OurServicesComponents/WebProgramming/BasicWebsiteDevelopmentPage"
  )
);
const EcommerceWebsitePage = lazy(() =>
  import("./pages/OurServicesComponents/WebProgramming/EcommerceWebsitePage")
);
const TechConsultingPage = lazy(() =>
  import("./pages/OurServicesComponents/CustomerServices/TechConsultingPage")
);
const TechSupportPage = lazy(() =>
  import("./pages/OurServicesComponents/CustomerServices/TechSupportPage")
);
const AndroidDevelopmentPage = lazy(() =>
  import(
    "./pages/OurServicesComponents/MobileDevelopment/AndroidDevelopmentPage"
  )
);
const IOSDevelopmentPage = lazy(() =>
  import("./pages/OurServicesComponents/MobileDevelopment/IOSDevelopmentPage")
);
const CrossPlatformDevelopmentPage = lazy(() =>
  import(
    "./pages/OurServicesComponents/CustomerServices/CrossPlatformDevelopmentPage"
  )
);
const MarketingServicePage = lazy(() =>
  import("./pages/OurServicesComponents/MarketingServicePage")
);
const SEOServicesPage = lazy(() =>
  import("./pages/OurServicesComponents/MarketingService/SEOServicesPage")
);
const ContentMarketingPage = lazy(() =>
  import("./pages/OurServicesComponents/MarketingService/ContentMarketingPage")
);
const CampaignAdsPage = lazy(() =>
  import("./pages/OurServicesComponents/MarketingService/CampaignAdsPage")
);
const BrandDesignPage = lazy(() =>
  import("./pages/OurServicesComponents/DesignServices/BrandDesignPage")
);
const DesignServicesPage = lazy(() =>
  import("./pages/OurServicesComponents/DesignServicesPage")
);
const DigitalArtPage = lazy(() =>
  import("./pages/OurServicesComponents/DesignServices/DigitalArtPage")
);
const LogoDesignPage = lazy(() =>
  import("./pages/OurServicesComponents/DesignServices/LogoDesignPage")
);
const SocialMediaDesignPage = lazy(() =>
  import("./pages/OurServicesComponents/DesignServices/SocialMediaDesignPage")
);
const VideoEditingPage = lazy(() =>
  import("./pages/OurServicesComponents/DesignServices/VideoEditingPage")
);
const ContactUs = lazy(() => import("./components/HomeComponents/ContactUs"));
const NotFound = lazy(() => import("./components/Errors/404"));

// import OurBlogPorts from "./components/OurBlog/OurBlogPorts";

const RoutesConfig = () => {
  return (
    <>
      <Suspense fallback={<div></div>}>
        <Navbar />
        <Routes>
          {/* ///////////////////////////////////////////////////////////////// */}
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<NotFound />} />

          {/* ///////////////////////////////////////////////////////////////// */}
          <Route path="/contactus" element={<ContactUs />} />
          {/* ///////////////////////////////////////////////////////////////// */}
          <Route path="/ourservices" element={<OurServicesPage />} />
          {/* //////////////////////////////////////////////////////////////// */}
          <Route
            path="/ourservices/webprogramming"
            element={<WebProgrammingPage />}
          />
          {/* ///////////////////////////////// */}
          <Route
            path="/ourservices/webprogramming/basicwebsitedevelopment"
            element={<BasicWebsiteDevelopmentPage />}
          />
          <Route
            path="/ourservices/webprogramming/ecommercewebsite"
            element={<EcommerceWebsitePage />}
          />
          <Route
            path="/ourservices/webprogramming/desktopsystem"
            element={<DesktopSystemPage />}
          />
          <Route
            path="/ourservices/webprogramming/debugging"
            element={<FixDebuggingPage />}
          />
          <Route
            path="/ourservices/webprogramming/controlpanel"
            element={<ControlPanelPage />}
          />
          {/* ///////////////////////////////// */}
          {/* ///////////////////////////////////////////////////////////////// */}
          <Route
            path="/ourservices/customerservices"
            element={<CustomerServicesPage />}
          />
          {/* ///////////////////////////////// */}
          <Route
            path="/ourservices/customerservices/techconsulting"
            element={<TechConsultingPage />}
          />
          <Route
            path="/ourservices/customerservices/techsupport"
            element={<TechSupportPage />}
          />
          {/* ///////////////////////////////// */}
          {/* ///////////////////////////////////////////////////////////////// */}
          <Route
            path="/ourservices/mobiledevelopment"
            element={<MobileDevelopmentPage />}
          />
          {/* ///////////////////////////////// */}
          <Route
            path="/ourservices/mobiledevelopment/androiddevelopment"
            element={<AndroidDevelopmentPage />}
          />
          <Route
            path="/ourservices/mobiledevelopment/iphoneprogramming"
            element={<IOSDevelopmentPage />}
          />
          <Route
            path="/ourservices/mobiledevelopment/crossplatformdevelopment"
            element={<CrossPlatformDevelopmentPage />}
          />
          {/* ///////////////////////////////// */}
          {/* ///////////////////////////////////////////////////////////////// */}
          <Route
            path="/ourservices/marketingservice"
            element={<MarketingServicePage />}
          />
          {/* ///////////////////////////////// */}
          <Route
            path="/ourservices/marketingservice/searchengineoptimazation"
            element={<SEOServicesPage />}
          />
          <Route
            path="/ourservices/marketingservice/contentmarketing"
            element={<ContentMarketingPage />}
          />
          <Route
            path="/ourservices/marketingservice/contentmarketing"
            element={<ContentMarketingPage />}
          />
          <Route
            path="/ourservices/marketingservice/adcampaign"
            element={<CampaignAdsPage />}
          />
          {/* ///////////////////////////////// */}
          {/* ///////////////////////////////////////////////////////////////// */}
          <Route
            path="/ourservices/designservices"
            element={<DesignServicesPage />}
          />
          {/* ///////////////////////////////// */}
          <Route
            path="/ourservices/designservices/branddesign"
            element={<BrandDesignPage />}
          />

          <Route
            path="/ourservices/designservices/logodesign"
            element={<LogoDesignPage />}
          />

          <Route
            path="/ourservices/designservices/videoediting"
            element={<VideoEditingPage />}
          />

          <Route
            path="/ourservices/designservices/socialmediadesign"
            element={<SocialMediaDesignPage />}
          />

          <Route
            path="/ourservices/designservices/digitalart"
            element={<DigitalArtPage />}
          />
          {/* ///////////////////////////////// */}
          {/* ///////////////////////////////////////////////////////////////// */}
          <Route path="/ourvision" element={<OurVisionPage />} />
          {/* ///////////////////////////////////////////////////////////////// */}
          <Route path="/blockchain" element={<BlockchainPage />} />
          {/* ///////////////////////////////// */}
          <Route path="/blockchain/consulting" element={<ConsultingPage />} />
          <Route path="/blockchain/training" element={<TrainingPage />} />
          <Route path="/blockchain/dap" element={<DapPage />} />
          <Route
            path="/blockchain/smart_contract_solution"
            element={<SmartContractSolutionPage />}
          />
          <Route
            path="/blockchain/integration_with_blockchain"
            element={<IntegrationWithBlockchainPage />}
          />
          <Route
            path="/blockchain/development_of_block_chain_network"
            element={<DevelopmentofBlockchainnetworkPage />}
          />
          {/* ///////////////////////////////// */}

          {/* ///////////////////////////////////////////////////////////////// */}
          <Route path="/digtiazation" element={<DigtiazationPage />} />
          {/* ///////////////////////////////////////////////////////////////// */}
          <Route path="/ourblog" element={<OurBlogPage />} />
          {/* <Route path="/ourblog/ourblogports" element={<OurBlogPorts />} /> */}
          {/* ///////////////////////////////////////////////////////////////// */}
        </Routes>
        <Footer />
      </Suspense>
    </>
  );
};

export default memo(RoutesConfig);
