import icon1 from "../assets/images/HomeImages/icon1.png";
import icon2 from "../assets/images/HomeImages/icon2.png";
import icon3 from "../assets/images/HomeImages/icon3.png";
import icon4 from "../assets/images/HomeImages/icon4.png";
import icon5 from "../assets/images/HomeImages/icon5.png";
import icon6 from "../assets/images/HomeImages/icon6.png";

export const dataServices = [
  {
    id: 1,
    title: {
      en: "Blockchain",
      ar: "البلوكتشين",
    },
    path: "/blockchain",
    image: icon1,
    alt: "icon1",
  },

  {
    id: 2,
    title: {
      en: "Digitization",
      ar: "الرقمنة",
    },
    path: "/digtiazation",
    image: icon2,
    alt: "icon2",
  },

  {
    id: 3,
    title: {
      en: "Web Application",
      ar: "مواقع الويب",
    },
    path: "/ourservices/webprogramming",
    image: icon3,
    alt: "icon3",
  },

  {
    id: 4,
    title: {
      en: "Apps",
      ar: "التطبيقات",
    },
    path: "/ourservices/mobiledevelopment",
    image: icon4,
    alt: "icon4",
  },

  {
    id: 5,
    title: {
      en: "Idea Generation",
      ar: "إنشاء الأفكار",
    },
    path: "/ourservices/marketingservice",
    image: icon5,
    alt: "icon5",
  },

  {
    id: 6,
    title: {
      en: "Designing",
      ar: "التصميم",
    },
    path: "/ourservices/designservices",
    image: icon6,
    alt: "icon6",
  },
];
