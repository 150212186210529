import React from "react";
import Header from "./../components/HomeComponents/Header";
import Services from "./../components/HomeComponents/Services";
import Vision from "./../components/HomeComponents/Vision";
import Web3 from "./../components/HomeComponents/Web3";
import BusinessDevelopment from "./../components/HomeComponents/BusinessDevelopment";
import ContactUs from "./../components/HomeComponents/ContactUs";
import Footer from "./../components/HomeComponents/Footer";

const HomePage = () => {
  return (
    <>
      <Header />
      <Services />
      <Vision />
      <Web3 />
      <BusinessDevelopment />
      <ContactUs />
      <Footer />
    </>
  );
};

export default HomePage;
