import React from "react";
import "../../App.css";
import Fade from "react-reveal/Fade";

const Title = (props) => {
  return (
    <div
      className=" lg:mt-10 lg:my-12 xl:mt-28  mt-5 xl:mb-12 mx-5"
      data-aos="fade-down"
    >
      <Fade top>
        <h5
          id="Our_Service"
          className={`${
            props.color ? "text-white" : "colorBtn"
          }  text-center xl:text-[32px] lg:text-3xl md:text-2xl text-xl  font-bold leading-9  tracking-[0.2px]  xl:mb-2`}
        >
          {props.question}
        </h5>
        <h2
          className={`${
            props.color || "text-white"
          } text-[ #FFF] text-center xl:text-5xl lg:text-4xl md:text-3xl text-2xl   font-bold xl:leading-[54px] leading-8 tracking-[0.2px]`}
        >
          {props.title}
        </h2>
      </Fade>
    </div>
  );
};

export default Title;
