import React, { memo, useState } from "react";
import Button from "../PublicComponents/Button";

import arrowRight from "../../assets/images/HomeImages/arrow-right.png";
import Image from "../PublicComponents/Image";
import image1 from "../../assets/images/HomeImages/image1.png";
import image3 from "../../assets/images/HomeImages/image3.webp";

import contactUs from "../../assets/images/HomeImages/contactUs.webp";
import whatsapp from "../../assets/images/HomeImages/whatsapp.webp";
import messenger from "../../assets/images/HomeImages/messenger.webp";
import phone from "../../assets/images/HomeImages/phone.webp";
import maps from "../../assets/images/HomeImages/maps.png";
import Fade from "react-reveal/Fade";
import Flip from "react-reveal/Flip";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

const Header = () => {
  const [open, setOpen] = useState(false);
  const [t, i18n] = useTranslation();

  const handlecontact = () => {
    setOpen(!open);
  };

  return (
    <div
      className={`MainBackground mt-12 ${
        i18n.language === "ar" ? "xl:mr-[50px]" : ""
      } xl:mx-[92px] lg:mx-8 mx-5`}
    >
      <Fade top cascade>
        <div className=" flex flex-col xl:flex-row justify-between items-center mb-2">
          <div
            className={`flex  ${
              i18n.language === "ar"
                ? "secondBackground-ar"
                : "secondBackground"
            }`}
          >
            <div
              className="flex flex-col gap-8 justify-center items-start "
              data-aos="fade-right"
            >
              <h2 className="text-[#ECECEC] xl:text-[64px] lg:text-5xl text-4xl  font-bold lg:leading-[70px]  ">
                {t("Digitize Your Company In The Most Efficient Way")}{" "}
              </h2>
              <p className=" text-[#939393] xl:text-[20px] lg:text-lg text-base font-medium lg:leading-8 tracking-[0.1px] mt-[-10px]">
                {t(
                  "Discover the world of digitization: Master your work with exceptional efficiency!"
                )}
              </p>

              <div className="flex flex-wrap gap-5 mt-10 xl:justify-start xl:items-center justify-center items-center xl:mx-0 mx-auto">
                <Link
                  target="_blank"
                  to="https://drive.google.com/file/d/1GdxuFAmF5y9tnXZkJ5KbWauhg3uZrsZr/view"
                  className={` hover:animate-bounce
                   colorBtn border-[#0076F0] border-b-[#1AA6DF] text-center  xl:w-[300px] ${
                     i18n.language === "ar"
                       ? "px-10 !text-lg py-[18px] font-bold"
                       : "px-14 py-4 font-semibold "
                   }   border rounded-md xl:text-xl text-lg  leading-5 flex items-center justify-center w-full`}
                >
                  {t("See Our Portoflio")}
                </Link>

                <Link
                  to="mailto:Info@nanoitcompany.com"
                  className="bg-[#1e1836] w-full xl:w-fit"
                >
                  <div
                    className={`animate-pulse btnBookFree flex xl:gap-12  justify-center items-center py-2 xl:py-3 ${
                      i18n.language === "ar" ? "px-4" : "px-5"
                    }   w-full xl:w-[320px] rounded-lg cursor-pointer border-2 border-solid xl:text-base lg:text-sm  font-semibold leading-5`}
                  >
                    <Button>{t("Book Free Consulting")}</Button>
                    <Image
                      src={arrowRight}
                      alt="arrowRight"
                      className={`animate-pulse ${
                        i18n.language === "ar" ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <span
            className={`mx-auto w-full lg:w-1/2 md:w-1/2 sm:w-1/2 ${
              i18n.language === "ar" ? "xl:w-1/2" : " xl:w-3/5"
            }`}
          >
            <Image src={image1} alt="image" />
          </span>
        </div>
      </Fade>
      <div className="w-full justify-end flex " dir="ltr">
        <span className=" justify-center items-center hidden lg:flex">
          <Image src={image3} alt="image" className="w-full" />
        </span>
        <div
          dir="ltr"
          onMouseEnter={handlecontact}
          onMouseLeave={handlecontact}
          className=" justify-center hidden xl:flex items-center gap-3  right-0 w-[175px] xl:h-[245px] "
        >
          <div
            className={`flex flex-col gap-5 ${open ? "visible" : "hidden"} ${
              i18n.language === "ar" ? "relative right-10" : ""
            }`}
          >
            <span className="relative left-24 top-5 cursor-pointer w-full hover:animate-bounce">
              <Flip bottom>
                <Link to="tel:00972593890010" target="_blank">
                  <Image src={phone} alt="phone" className="w-full" />
                </Link>
              </Flip>
            </span>
            <span className="cursor-pointer relative left-6  w-full hover:animate-bounce">
              <Flip bottom>
                <Link
                  to="https://www.facebook.com/Nano.IT.Company"
                  target="_blank"
                >
                  <Image src={messenger} alt="messenger" className="w-full" />
                </Link>
              </Flip>
            </span>
            <span className="cursor-pointer relative left-6 w-full hover:animate-bounce">
              <Flip bottom>
                <Link
                  to="https://api.whatsapp.com/send?phone=00972593890010"
                  target="_blank"
                  className="w-full"
                >
                  <Image src={whatsapp} alt="whatsapp" className="w-full" />
                </Link>
              </Flip>
            </span>
            <span className="relative left-24 bottom-5 cursor-pointer w-full hover:animate-bounce">
              <Flip bottom>
                <Link
                  to="https://www.google.com/maps/place/31%C2%B030'34.6%22N+34%C2%B026'26.3%22E/@31.5097186,34.4407017,19z/data=!4m4!3m3!8m2!3d31.5096111!4d34.4406389?hl=ar&entry=ttu"
                  target="_blank"
                >
                  <Image src={maps} alt="maps" className="w-full " />
                </Link>
              </Flip>
            </span>
          </div>
          <span
            className={`hover:animate-pulse
            h-[180px] hover:cursor-pointer flex justify-center items-center absolute right-3 ${
              i18n.language === "ar" ? "secondBackground-ar" : ""
            }`}
          >
            <Image src={contactUs} alt="contactUs" className="w-full" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
