import React from "react";
import Image from "../PublicComponents/Image";
import { Link } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";
import { memo } from "react";

const CardDetails = (props) => {
const [t, i18n] = useTranslation();
  return (
    <div className={props.className}>
      <span className="">
        <Image src={props.src} alt={props.alt} className="w-full" />
      </span>
      <div className="flex flex-col gap-3">
        <span className="font-semibold leading-6">{props.head}</span>
        <h3 className="text-3xl font-extrabold leading-10 ">{props.title}</h3>
      </div>
      <p className="text-base font-normal leading-8">{props.description}</p>
      <Link
        to="/contactus"
        className="hover:animate-bounce colorBtn border-[#0076F0] border-b-[#1AA6DF]  py-3 px-14 border rounded-md font-semibold text-base text-center"
      >
        {t("Learn More")}
      </Link>
    </div>
  );
};

export default memo(CardDetails);
