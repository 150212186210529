import React from "react";
import Title from "../PublicComponents/Title";
import "../../App.css";
import Rotate from "react-reveal/Rotate";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useRef } from "react";
import { memo } from "react";

const ContactUs = () => {
  const [t, i18n] = useTranslation();

  const [ele, setEle] = useState(false);

  const refName = useRef("");
  const refEmail = useRef("");
  const refSubject = useRef("");
  const refPhone = useRef("");
  const refTextarea = useRef("");

  const handleForm = (e) => {
    e.preventDefault();

    const valueName = refName.current.value;
    const valueEmail = refEmail.current.value;
    const valueSubject = refSubject.current.value;
    const valuePhone = refPhone.current.value;
    const valueTextarea = refTextarea.current.value;

    if (
      valueName.length >= 1 &&
      valueEmail.length >= 1 &&
      valueSubject.length >= 1 &&
      valuePhone.length >= 1 &&
      valueTextarea.length >= 1
    ) {
      setEle(true);
      refName.current.value = "";
      refEmail.current.value = "";
      refSubject.current.value = "";
      refPhone.current.value = "";
      refTextarea.current.value = "";
    } else {
      setEle(false);
    }
  };

  return (
    <div
      className={`${
        i18n.language === "ar" ? "xl:mr-[50px]" : ""
      } xl:mx-[92px] lg:mx-10 mx-5`}
    >
      <div id="contactus">
        <Title
          question={t("Contact Us")}
          title={t("Because We Care About Our Customer")}
        />
      </div>

      <div className="flex xl:flex-row flex-col gap-16 xl:gap-0  justify-between items-start xl:mt-20 mt-10 mb-36">
        <Rotate top left cascade>
          <div className="flex flex-wrap flex-col xl:w-[29%] w-full xl:gap-10 gap-5">
            <div className="flex flex-wrap gap-3 flex-col">
              <h1 className="text-[#6A6A6A] text-sm  sm:text-base font-bold leading-6 tracking-[1.72px]">
                <span className="text-[#FF3838] pr-2">// </span>
                {t("Free Consulting")}
              </h1>
              <h2 className="text-[#fff] xl:text-[42px] lg:text-4xl md:text-3xl text-2xl  font-extrabold xl:leading-[50px] leading-10  ">
                {t("We are here to help you & your business")}
              </h2>
            </div>

            <p className="text-[#8F8F8F] sm:text-sm text-xs xl:text-base  font-normal !leading-7 items-start">
              {t(
                "Feel Free To Contact Us Any Time You Want We Are available 24/7"
              )}
            </p>
            <div>
              <span className="text-sm sm:text-base font-normal leading-7 text-[#fff]">
                {t("Timings")}
              </span>
              <h3 className="colorBtn sm:text-[22px] text-xl font-medium leading-[43px] ">
                {t("One Hour Meeting For Free")}
              </h3>
            </div>
          </div>
        </Rotate>

        <form
          onSubmit={handleForm}
          className="flex  xl:w-[700px]  w-full  flex-wrap  justify-between items-center gap-3"
        >
          <input
            ref={refName}
            type="text"
            name="Your Name"
            placeholder={t("Your Name")}
            id="Your Name"
            className="xl:w-[49%] w-full hover:outline-none outline-none rounded-sm  text-[#6A6A6A] py-5 px-7 border border-[#0A090F] bg-[#0A090F] border-solid hover:border-[#6A6A6A] focus:border-[#6A6A6A]"
          />
          <input
            ref={refEmail}
            type="email"
            name="Your Email"
            placeholder={t("Your Email")}
            id="Your Email"
            className="xl:w-[49%] w-full hover:outline-none outline-none rounded-sm  text-[#6A6A6A]  py-5 px-7 border border-[#0A090F] bg-[#0A090F] border-solid hover:border-[#6A6A6A] focus:border-[#6A6A6A]"
          />
          <input
            ref={refSubject}
            type="text"
            name="Subject"
            placeholder={t("Subject")}
            id="Subject"
            className="xl:w-[49%]  w-full hover:outline-none outline-none rounded-sm  text-[#6A6A6A]   py-5 px-7 border border-[#0A090F] bg-[#0A090F] border-solid hover:border-[#6A6A6A] focus:border-[#6A6A6A]"
          />
          <input
            ref={refPhone}
            type="tel"
            name="Phone"
            placeholder={t("Phone")}
            id="Phone"
            className="xl:w-[49%]  w-full  hover:outline-none outline-none rounded-sm text-[#6A6A6A] py-5 px-7 border border-[#0A090F] bg-[#0A090F] border-solid hover:border-[#6A6A6A] focus:border-[#6A6A6A]"
          />

          <textarea
            ref={refTextarea}
            name=""
            id=""
            cols="30"
            rows="10"
            placeholder={t("Write your message here")}
            className="hover:outline-none outline-none rounded-sm w-full h-44 text-[#6A6A6A] p-5  border border-[#0A090F] bg-[#0A090F] border-solid hover:border-[#6A6A6A] focus:border-[#6A6A6A]"
          ></textarea>

          <button
            type="submit"
            className="hover:animate-pulse animate-ease-linear
            lg:w-64 w-full colorBtn border-[#0076F0] border-b-[#1AA6DF]  py-3 px-16 border rounded-md font-semibold text-base text-center"
          >
            {t("Send Message")}
          </button>
          {ele && (
            <p className="text-xs colorBtn ">
              {t("The message has been sent successfully ...")}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default memo(ContactUs);
