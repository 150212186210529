import React from "react";
import { Helmet } from "react-helmet";

function OrganizationSchema() {
  return (
    <Helmet>
      <script type="application/ld+json">
        {`
          {
            "url": "https://nanoitcompany.com/",
            "logo": "../public/newlogo.png",
            "org": "Nano IT Company",
            "type": "Nano IT Co specializes in high-quality custom software solutions for businesses,  Trust our expertise for digital success.
          }
        `}
      </script>
    </Helmet>
  );
}

export default OrganizationSchema;
