import React, { memo } from "react";
import Title from "../PublicComponents/Title";
import Image from "../PublicComponents/Image";
import map from "../../assets/images/HomeImages/map.webp";
import { Link } from "react-router-dom";
import Pulse from "react-reveal/Pulse";
import { useTranslation } from "react-i18next";

const Vision = () => {
  const [t, i18n] = useTranslation();

  return (
    <div
      className={`${
        i18n.language === "ar" ? "xl:mr-[50px]" : ""
      } xl:mx-[92px] lg:mx-10 mx-5`}
    >
      <div id="Vision" className="xl:mx-[20%]">
        <Title
          question={t("Our Vision")}
          title={t(
            "Unleashing Innovation: Elevating Technology to New Heights"
          )}
        />
      </div>

      <div>
        <Pulse>
          <p className="xl:px-[245px] text-xs lg:text-sm xl:text-[15px] font-normal leading-7 pt-10 text-center text-[#888]">
            {t(
              "Embracing a Sustainable Future: Leading the way with tech innovations that promote fairness and minimize environmental impact. Tracking goods, fostering equality, and powered by renewables, we're shaping a brighter tomorrow."
            )}
          </p>

          <div className="flex justify-center items-center flex-col my-8">
            <span>
              <Image src={map} alt="map" className="w-full" />
            </span>

            <Link
              to="/contactus"
              className="animate-bounce border-[#0076F0] border-b-[#1AA6DF] lg:mt-[-120px] text-white text-center border rounded py-4 px-12 tracking-[0.764px] leading-5 font-bold text-sm"
            >
              {t("Explore Now")}
            </Link>
          </div>
        </Pulse>
      </div>
    </div>
  );
};

export default memo(Vision);
