import React, { memo } from "react";
import Image from "../PublicComponents/Image";

import twitter from "../../assets/images/HomeImages/twitter.png";
import linkedin from "../../assets/images/HomeImages/linkedin.png";
import facebook from "../../assets/images/HomeImages/facebook.png";
import FooterHome from "../../assets/images/HomeImages/FooterHome.webp";
import { Link } from "react-router-dom";
import Flash from "react-reveal/Flash";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const [t, i18n] = useTranslation();
  return (
    <Flash>
      <div className="flex ">
        <span className="x:w-4/5 w-full h-full">
          <Image src={FooterHome} alt="FooterHome" className="w-full h-full" />
        </span>
        <div className="relative right-2 lg:right-10 md:gap-6  gap-4 flex flex-col justify-end items-center w-1/2 ">
          <h3 className="colorFooter text-base sm:text-3xl  md:text-4xl lg:text-5xl xl:text-[64px]  font-bold xl:leading-[74px] lg:leading-[60px]  leading-5 text-center text-transparent ">
            <span className="">{t("Be The First")}</span>
            <br />
            <span>{t("In The New")}</span>
            <br />
            <span>{t("World")}</span>
          </h3>
          <div className=" flex justify-around items-center xl:gap-5 gap-2">
            <Link to="https://www.facebook.com/Nano.IT.Company" target="_blank">
              <Image
                src={facebook}
                alt="facebook"
                className="w-6 sm:w-10 md:w-12 lg:w-full xl:w-full"
              />
            </Link>
            <Link
              to="https://www.linkedin.com/company/nano-it-co/mycompany"
              target="_blank"
            >
              <Image
                src={linkedin}
                alt="linkedin"
                className="w-6 sm:w-10 md:w-12 lg:w-full xl:w-full"
              />
            </Link>
            <Link to="https://twitter.com/NanoITCompany" target="_blank">
              <Image
                src={twitter}
                alt="twitter"
                className="w-6 sm:w-10 md:w-12 lg:w-full xl:w-full"
              />
            </Link>
          </div>
        </div>
      </div>
    </Flash>
  );
};

export default memo(Footer);
