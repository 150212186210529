import React from "react";
import Title from "../PublicComponents/Title";
import ServicesCard from "./ServicesCard";
import Flash from "react-reveal/Flash";
import { useTranslation } from "react-i18next";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { memo } from "react";

const Services = () => {
  const [t, i18n] = useTranslation();
  return (
    <div>
      <div className="xl:mx-24  xl:mt-[-100px] ">
        <Title
          question={t("Our Services")}
          title={t("Creating Solution For All Your Needs")}
        />
      </div>

      <Fade In Right>
        <div className="xl:mx-[92px] lg:mx-10 mx-5 flex flex-wrap gap-20 xl:gap-0 justify-start items-center mb-20">
          <div className="xl:w-1/2 mt-16 lg:mt-0  w-full flex gap-5  justify-center flex-col items-start ">
            <div className="flex flex-col gap-4">
              <div className="gap-1 flex justify-start items-center xl:text-[17px] text-sm font-semibold leading-6 tracking-[1.7px]">
                <span className="text-[#1AA6DF]">//</span>
                <h4 className="text-[#7E7E7E] ">{t("Best Services")}</h4>
              </div>
              <h2 className="text-[#FFF]  xl:text-[42px] lg:text-4xl text-3xl font-extrabold !leading-[52px] ">
                {t(
                  "Every service you require to elevate your company to the pinnacle of success."
                )}
              </h2>
            </div>
            <p className="text-[#908F8D] xl:text-[15px] text-sm font-normal leading-[29px] ">
              {t(
                "We're not your run-of-the-mill coding company; we're your trusted partners in turning ideas into digital victories. With our devoted squad, we cook up solutions that give life to your vision, propelling your success forward with an unyielding passion at every step."
              )}
            </p>
            <Link
              to="/contactus"
              className="border-[#0076F0] border-b-[#1AA6DF]  text-[15px] font-bold leading-[21px] tracking-[0.8px] border py-[18px] px-12 rounded-lg"
            >
              {t("Explore Now")}
            </Link>
          </div>

          <Flash>
            <div className="xl:w-1/2 flex  xl:justify-end justify-center flex-wrap gap-6 gap-y-6  w-full">
              <ServicesCard />
            </div>
          </Flash>
        </div>
      </Fade>
    </div>
  );
};

export default memo(Services);
