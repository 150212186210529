import { BrowserRouter, HashRouter } from "react-router-dom";
import RoutesConfig from "./RoutesConfig";
import { useTranslation } from "react-i18next";
import OrganizationSchema from "./OrganizationSchema";
import { useEffect } from "react";

function App() {
  const [t, i18n] = useTranslation();

  useEffect(() => {
    const currentDomain = window.location.hostname;
    const preferredDomain = "www.nanoitcompany.com";
    if (currentDomain !== preferredDomain) {
      window.location.href = `https://${preferredDomain}${window.location.pathname}`;
    }
  }, []);

  return (
    <div dir={t("t")}>
      <OrganizationSchema />
      <HashRouter>
        <RoutesConfig />
      </HashRouter>
    </div>
  );
}

export default App;
